import React from 'react';
import Dialog from '../molecules/Dialog';
import Menu from '../molecules/Menu';

export default function AppSwitcher({mobile}) {

    if (mobile)
    return (
        <Dialog
            tooltip='TNM Group Apps'
            menuIcon={{base: 'fa-thin', className: 'fa-grid', color: '#FFFFFF'}}
            options={[
                {link: 'https://www.northernminer.com/', external: true, text: 'The Northern Miner', icon: {base: 'fa-solid', className: 'fa-newspaper'}},
             ]}
        />
    )
    else return (
        <Menu
            tooltip='TNM Group Apps'
            menuIcon={{base: 'fa-thin', className: 'fa-grid', color: '#FFFFFF'}}
            options={[
                {link: 'https://www.northernminer.com/', external: true, text: 'The Northern Miner', icon: {base: 'fa-solid', className: 'fa-newspaper'}},
                {link: 'https://northernminer.com/tnm-maps/', external: true, text: 'TNM Maps', icon: {base: 'fa-solid', className: 'fa-map'}},
            ]}
        />
    )
}
