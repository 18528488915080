import React, { createContext, useContext, useReducer } from 'react';
import { useApp } from '../../../AppContext';
import { checkboxSelect } from '../../../lib/checkbokSelect';
import { definePortfolioCheckbox } from '../../../lib/definePortfolioCheckbox';
import { gatherIds } from '../../../lib/gatherIds';
import { serviceGetPortfolio, serviceUpdateCompanyList, serviceUpdatePropertyList, serviceGetFolders, deletePortfolioFolder, deletePortfolioFolderItem, postPortfolioItemMove } from './middleware';
import { portfolioReducer } from './reducer';

const PortfolioContext = createContext();

export const PortfolioProvider = ({ children }) => {
    const initialState = {
        resultsFolder: [],
        resultsFolderProperty: [],
        resultsFolderContact: [],
        portfolio: {
            savedCompanyList: [],
            savedPropertyList: []
        }
    }

    const { handleLoading, handleError } = useApp();
    const [state, dispatch] = useReducer(portfolioReducer, initialState);

    const setPortfolio = () => {
        handleLoading(true);
        serviceGetPortfolio().then(data => {
            const portfolio = definePortfolioCheckbox(data.data);
            dispatch({ type: "SET_PORTFOLIO", payload: portfolio });
            handleLoading(false);
        }).catch(err => {
            handleLoading(false);
            handleError(err.response.headers.errormessage);
        });
    }

    const setFolders = (EntityId) => {
        serviceGetFolders(EntityId).then(data => {
            if (EntityId === 1) {
                    const resultsFolder = data.data;
                    dispatch({ type: 'SET_RESULTS_Folder', payload: resultsFolder });
            }
            if (EntityId === 2) {
                    const resultsFolder = data.data;
                    dispatch({ type: 'SET_RESULTS_Folder_Property', payload: resultsFolder });
            }
            if (EntityId === 3) {
                    const resultsFolder = data.data;
                    dispatch({ type: 'SET_RESULTS_Folder_contact', payload: resultsFolder });
            }
        }).catch(err => {
            console.error(err);
            handleError(err.response.headers.errormessage);
        });
    }

    const handlePortfolioFolder = (data) => {
        deletePortfolioFolder(data).then(data => {
            setTimeout(() => {
                setFolders(1);
                setFolders(2);
                setFolders(3);
                setPortfolio();
            }, 100);
        }).catch(err => {
            console.error(err);
            handleError(err.response.headers.errormessage);
        });
    }

    const handlePortfolioFolderItem = (data) => {
        deletePortfolioFolderItem(data).then(data => {
            setTimeout(() => {
                setFolders(1);
                setFolders(2);
                setFolders(3);
                setPortfolio();
            }, 100);
        }).catch(err => {
            console.error(err);
            handleError(err.response.headers.errormessage);
        });
    }
    const handleSelected = e => {
        const payload = checkboxSelect(state.portfolio, e.target);
        dispatch({ type: "SET_PORTFOLIO", payload: payload });
    }
    const updateCompanyList = () => {
        const portfolioIds = gatherIds(state.portfolio.savedCompanyList);
        serviceUpdateCompanyList(portfolioIds).then(data => {
            const portfolio = definePortfolioCheckbox(data.data);
            dispatch({ type: "SET_PORTFOLIO", payload: portfolio });
        }).catch(err => {
            console.error(err);
            handleError(err.response.headers.errormessage);
        });
    }
    const updatePropertyList = () => {
        const portfolioIds = gatherIds(state.portfolio.savedPropertyList);
        serviceUpdatePropertyList(portfolioIds).then(data => {
            const portfolio = definePortfolioCheckbox(data.data);
            dispatch({ type: "SET_PORTFOLIO", payload: portfolio });
        }).catch(err => {
            console.error(err);
            handleError(err.response.headers.errormessage);
        });
    }

    const addToPortfolioMove = (mPortfolioId, mFolderId) => {
        const body = { PortfolioId: parseInt(mPortfolioId), FolderId: parseInt(mFolderId) }
        postPortfolioItemMove(body).then(() => {
            setPortfolio();
        }).catch(err => {
            console.error(err);
            handleError(err.response.headers.errormessage);
        })

    }

    const providerValue = {
        ...state,
        setPortfolio,
        handleSelected,
        updateCompanyList,
        updatePropertyList,
        setFolders,
        handlePortfolioFolder,
        handlePortfolioFolderItem,
        addToPortfolioMove
    }

    return (
        <PortfolioContext.Provider value={providerValue}>
            {children}
        </PortfolioContext.Provider>
    )
}

export const usePortfolio = () => useContext(PortfolioContext);