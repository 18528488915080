import * as React from 'react';
import { Route } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import Layout from './components/Layout';
import CompanyListing from './pages/CompanyListing';
import CompanyProfile from './pages/CompanyProfile';
import PropertyProfile from './pages/PropertyProfile';
import PropertyListing from './pages/PropertyListing';
import PeopleListing from './pages/PeopleListing';
import ContactUs from './pages/ContactUs';
import MinePersonnelListing from './pages/MinePersonnelListing';
import Portfolio from './pages/Portfolio';
import Login from './pages/Login';

import './custom.css'
import { CompanyProvider } from './components/features/company/context';
import { PropertyProvider } from './components/features/property/context';
import { PeopleProvider } from './components/features/people/context';
import { MinePersonnelProvider } from './components/features/mine-personnel/context';
import { PortfolioProvider } from './components/features/portfolio/context';

import { AppProvider } from './AppContext';
// import ChatBot from './pages/ChatBot';


export default () => {
  TagManager.initialize({ gtmId: 'GTM-PL9CMB2' });
 
  return (
        <Router>
            <AppProvider>
              <Route exact path={['/login', '/executives', '/personnel', '/companies', '/company/:companyId', '/properties', '/properties/:propertyId', '/my-portfolio', '/', '/contactus', '/chatbot']}>
                    <Layout>
                        <Route exact path='/login' component={Login} />
                        <CompanyProvider>
                            <PeopleProvider>
                                <Route exact path='/executives' component={PeopleListing} />
                            </PeopleProvider>
                            <MinePersonnelProvider>
                              <Route exact path='/personnel' component={MinePersonnelListing} />
                            </MinePersonnelProvider>
                            <PropertyProvider>
                                <Route exact path='/companies' component={CompanyListing} />
                                <Route exact path='/' component={CompanyListing} />
                                <Route path='/company/:companyId' component={CompanyProfile} />
                                <Route exact path='/properties' component={PropertyListing} />
                                <Route path='/properties/:propertyId' component={PropertyProfile} />
                                

                                <PortfolioProvider>
                                    <Route path='/my-portfolio' component={Portfolio} />
                                </PortfolioProvider>
                            </PropertyProvider>
                          <Route path='/contactus' component={ContactUs} />
                         
                        </CompanyProvider>
                        
                    </Layout>
                    {/* <Route exact path='/chatbot' component={ChatBot} /> */}
                </Route>
                
            </AppProvider>
          
        </Router >
          
    )
};