const development = [
   'Closed', 'Resources - Historic', 'Pre-feasibility', 'Preliminary Economic Assessment', 'Resources',
    'Exploration', 'Production', 'Grass Roots', 'Feasibility', 'Permitting', 'Construction', 'Reclamation', 'Commissioning', 'Scoping'


];

const activity = [
    'Active', 'Abandoned', 'Inactive', 'Care & Maintenance', 'Suspended', 'Temporarily Suspended'

]

const market = [
    '< US$500 mi',
    'US$500 mi - 1 bi',
    '> US$1 bi'
];

const lists = {development: development, activity: activity, market: market};

export const reorderDropdowns = (dropdown, listType) => {
    let reordered = [];
    
    lists[listType].forEach(orderOption => {
        const fullOption = dropdown.find(option => option.name === orderOption);
        if (fullOption) {
            reordered.push(fullOption);  // Only push if fullOption is defined
        }
    });

    return reordered;
};


export const filterUndefined = (dropdown) => {
    let filtered = dropdown.filter(option => option.name !== 'Undefined')
    return filtered;
}