import React from 'react';
import { Icon, Tooltip, styled } from '@mui/material';
import { useLocation } from 'react-router';

import { useApp } from '../../../AppContext';

import Button from '../atoms/Button';
import Link from '../atoms/Link';
import Ad from '../molecules/Ad';
import NavMenum from '../molecules/NavMenu';
import UserMenu from '../molecules/UserMenu';
import AppSwitcher from './AppSwitcher';

import { membershipUrl } from '../../../lib/membershipUrl';

const StyledHeader = styled('header')(() => ({
    backgroundColor: '#413F3F',
    height: '46px',
    padding: '5px 5px 5px 8px',
    margin: '10px',
    borderRadius: '6px',
    boxShadow: '0px 3px 5px 0px #00000026',
    '& .header-wrapper': {
        display: 'flex',
        justifyContent: 'space-between',
    },
    '& img': {
        height: '20px',
        marginRight: '9px'
    },
    '& .header-nav': {
        display: 'flex',
        alignItems: 'center'
    }
}))

const StyledFueledText = styled('p')(({theme}) => ({
    display: 'flex',
    fontFamily: "'Zilla Slab', serif",
    fontWeight: 400,
    lineHeight: 1,
    marginBottom: 0,
    backgroundColor: 'rgb(255 255 255 / 50%)',
    width: 'fit-content',
    padding: '5px',
    borderRadius: '4px',
    alignSelf: 'flex-start',
    color: '#413F3F',
    '& a': {
        color: '#413F3F',
        marginLeft: 3,
        '&:hover': {
            color: '#2D70DA'
        }
    },
    '& span': {
        fontWeight: 700
    }
}))

const StyledDiv = styled('div')(() => ({
    border: 0
}))

const ChatbotButtonStyle = styled('p')(({theme}) => ({
    display: 'flex',
    fontFamily: "'Zilla Slab', serif",
    fontWeight: 400,
    lineHeight: 1,
    marginBottom: 0,
    backgroundColor: '#2d70ad',
    width: 'fit-content',
    padding: '5px',
    borderRadius: '4px',
    alignSelf: 'flex-start',
    color: '#38648D',
    '& a': {
        color: '#38648D',
        marginLeft: 3,
        '&:hover': {
            color: 'white !important',
            backgroundColor: '#2d70ad !important'
        }
    },
    '& span': {
        fontWeight: 700
    }

}))

export default function Header() {
    const { user } = useApp();
    const location = useLocation();

    const handleLogin = () => {
        window.location.href = membershipUrl() + 'login?src=' + window.location.host;
    };
    
      

  return (
    <StyledDiv sx={{zIndex: 1, position: 'relative', display: 'flex', flexDirection: 'column'}}>
        <StyledHeader className='fixed-top'>
            <div className='header-wrapper'>
                <div className='header-nav'>
                    <NavMenum mobile />
                    <Link exact link='/' type='img hd'>
                        <img
                            alt='Marco Polo logo'
                            src='/images/Marco-Polo-Logo.png'
                        />
                    </Link>
                    <NavMenum />
                    {/* <ChatbotButtonStyle>
                       <Link link='/chatbot'  openTab type='hdr'>A.I. SEARCH</Link>
                    </ChatbotButtonStyle> */}
                </div>
                <StyledDiv sx={{display: {xs: 'none', sm: 'none', md: 'flex'}}}>
                    <Tooltip title='User Guide'>
                        <Link type='icon-hdr' openTab external link='http://marcopolo.tnm.global/wp-content/uploads/2022/09/2022-09-02-Marco-Polo-User-Guide.pdf' >
                            <Icon baseClassName='fa-thin' className='fa-square-question'/>
                        </Link>
                    </Tooltip>
                    <Tooltip title="What's New">
                        <Link type='icon-hdr' openTab external link='https://marcopolo.tnm.global/whats-new/' >
                            <Icon baseClassName="fa-thin fa-megaphone"/>
                        </Link>
                    </Tooltip>
                    <AppSwitcher />
                    {user.authenticated ? (
                        <UserMenu user={user} type='menu' />
                    ) : (
                        <Button type='text' handleClick={() => handleLogin()} className='header-btn'>Log in</Button>
                    )}
                </StyledDiv>
            </div>
        </StyledHeader>

        <StyledDiv sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'column', md: 'row'}, alignItems: {xs: 'center', sm:'center', md: 'flex-start'}, justifyContent: 'space-between', margin: '5px 20px 0px'}}>
            <div className='print-logo'>
                <img alt='Marco Polo printing logo' src='/images/Print-Logo.png' />
            </div>

            <StyledFueledText sx={{ fontSize: {xs: '1rem', sm: '1.25rem'}, marginTop:'70px'}}>
                fueled with 
                <Link external openTab link='https://digigeodata.com/'>
                    Digi<span>GeoData</span>
                </Link>
            </StyledFueledText>

            {(location.pathname === '/' ||
            location.pathname === '/properties' ||
            location.pathname === '/executives') &&
                <Ad />
            } 
        </StyledDiv>
    </StyledDiv>
  )
}
