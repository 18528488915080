import React from 'react';

import { useCompany } from '../../../features/company/context';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import Card from '../../molecules/Card';
import Form from '../../molecules/Form';
import MultipleSearch from '../../molecules/MultipleSearch';

export default function CompanyFilter() {
  const {
    handleAutoComplete,
    filters,
    emptyFilters,
    autocomplete,
    companySearchValues,
    handleFilters,
    handleSearchMultipleInput,
    handleCompanyName,
    handleSearchFilter,
    handleClearFilters,
    handleClearAutoComplete,
    MarketCap_Slider_val,
    query } = useCompany();
  
  return (
    <div className="search-filter">
      <MultipleSearch
        page='companies'
        search={handleSearchMultipleInput}
        inputValue={companySearchValues}
        fetch={handleAutoComplete}
        autocomplete={autocomplete}
        handleInput={handleCompanyName}
        clearOptions={handleClearAutoComplete}
      />
      <Card styles={{marginTop: '16px', marginBottom: '24px'}}>
              <Text fontFamily="ff1" fontWeight='600' fontSize='1.125rem'>Filter by Characteristics</Text>
        <hr />
        <Form filters={filters} page='companies' values={query.filter} handleInput={handleFilters} MarketCap_Slider_val={MarketCap_Slider_val} />
        <div className='filters-actions'>
          <Button type='text' handleClick={() => handleClearFilters()}>Reset</Button>
          <Button handleClick={() => handleSearchFilter()} disabled={emptyFilters}>See Results</Button>
        </div>
      </Card>
    </div>
  )
}
