import React from 'react'
import { styled } from '@mui/material'
import Text from '../atoms/Text'
import Link from '../atoms/Link'

const StyledFooter = styled('div')(() => ({
    backgroundColor: '#413F3F',
    padding: '20px',
    height: '57px',
    '& .content': {
        display: 'flex',
        justifyContent: 'space-between',
        margin: 'auto'
    },
    '& .content .footer-section': {
        display: 'flex',
        alignItems: 'center'
    }
}))

const StyledImg = styled('img')(() => ({
    border: 'none'
}))

export default function Footer() {
  return (
    <footer>
        <StyledFooter className="copyright" sx={{height: {xs: '52px', sm: '57px'}}}>
            <div className="content">
                <div className='footer-section'>
                    <StyledImg
                        sx={{height: {xs: '10px', sm: '15px'}, marginRight: {xs: '5px', sm: '10px'}}} 
                        alt='The Northern Miner Group logo'
                        src='/images/TNMG-Logo.png'
                        
                    />
                    <Link 
                        external
                        type='footer'
                        link='https://www.northernminer.com/privacypolicy/'
                        openTab
                        sx={{
                            fontSize: {
                                xs: '0.625rem', sm:'0.875rem'
                            },
                            '::after': {
                                content: '"|"',
                                margin: {
                                    xs: '0 2px', sm: '0 5px'
                                }
                            }
                        }}
                    >
                        Policies
                    </Link>
                    <Link 
                        external
                        type='footer'
                        link='https://www.northernminer.com/contact/'
                        openTab
                        sx={{
                            fontSize: {
                                xs: '0.625rem', sm:'0.875rem'
                            }
                        }}
                    >
                        Contact
                    </Link>
                </div>
                
                <div>
                    <Text color='#fff' fontSize={{xs: '0.625rem', sm:'0.875rem'}} fontFamily='ff1'>© TNM Group | All Rights Reserved</Text>
                </div>
            </div>
        </StyledFooter>
    </footer>
  )
}
