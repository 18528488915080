export const membershipUrl = () => {
    const currentLocation = window.location.href;
    if (currentLocation.includes('localhost')) {
        return 'https://membership.tnm.global/'
        ////'https://localhost:6001/'
    } else if (currentLocation.includes('stage')) {
        return 'https://stagemembership.tnm.global/'
    } else {
        return 'https://membership.tnm.global/';
    }
}