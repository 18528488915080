export const defineQueryFilters = (queryState, filters) => {
    let newFilters = { ...queryState.filter };
    if (typeof filters == "object") {
        Object.entries(filters).map(([key, value]) => {
            if (Array.isArray(value)) {
                const filterSelected = value.filter(option => option.isSelected === true);
                if (key.includes('Dropdown') || key.includes('position')) {
                    !filterSelected.length ? newFilters[key] = '' : newFilters[key] = filterSelected[0].selectedValue;
                }
                else {
                    !filterSelected.length ? newFilters[key] = [] : newFilters[key] = filterSelected;
                }
            }
            else {
                newFilters[key] = value.value;
            }
            return key;
        })
    }
    
    const newQuery = {...queryState, filter: newFilters};

    const emptyQuery = Object.values(newFilters)
    .some(array => array.length !== 0);
    newQuery.isDefaultView = !emptyQuery;
    return newQuery;
}