import React, { createContext, useContext, useReducer, useState } from 'react';
import { useHistory } from 'react-router';

import { useApp } from '../../../AppContext';
import {companyReducer} from './reducer';
import { serviceCompanyAutocomplete, serviceCompanyCities, serviceGetFilters, serviceGetProfile, servicePostList, serviceAddPortfolio, serviceGetFolders } from './middleware';

import { checkboxSelect } from '../../../lib/checkbokSelect';
import { clearFilters } from '../../../lib/clearFilters';
import { defineQueryFilters } from '../../../lib/defineQueryFilters';
import { dropdownSelect } from '../../../lib/dropdownSelect';
import { handleFiltersList } from '../../../lib/handleFiltersApplied';
import { handleNameInput } from '../../../lib/handleNameInput';
import { deletePortfolioItem, postPortfolioItem } from '../middleware/middleware';
import { managePortfolioSearch } from '../../../lib/managePortfolioSearch';
import { reorderDropdowns } from '../../../lib/reorderDropdowns';

const CompanyContext = createContext();

export const CompanyProvider = ({children}) => {
    const history = useHistory();
    const defaultSort = {fieldName: 'CDisplayName', direction: 0};

    const initialState = {
        filters: {},
        selectedFilters: [],
        companyName: [],
        companySearchValues: [],
        query: {
            companyName: [],
            filter: {},
            option: 1,
            pageSize: 50,
            pageNumber: 1,
            isDefaultView: true,
            sorting: defaultSort
        },
        results: [],
        resultsFolder: [],
        MarketCap_Slider_val: [0, 500000],
        totalResults: 0,
        selected: '',
        profile: {},
        searchType: 0,
        autocomplete: [],
        emptyFilters: true,
        graphId: { value: null, label: '' }
    }

    const { handleLoading, handleError } = useApp();
    const [state, dispatch] = useReducer(companyReducer, initialState);
    const [sliderVal, setSliderVal] = useState("");

    const handleQuery = data => {
        const query = defineQueryFilters(state.query, data);
        query["filter"]["MarketCap_Slider"] = sliderVal;
        dispatch({type: 'SET_QUERY', payload: query});
        dispatch({type: 'SET_EMPTY_FILTERS_FLAG', payload: query.isDefaultView});
    }
    const setFilters = () => {
        serviceGetFilters().then(data => {
            dispatch({type: 'CLEAR_PROFILE'});
            const filters = data.data;
            filters.marketCapList_Checkbox = reorderDropdowns(filters.marketCapList_Checkbox, 'market');
            handleQuery(filters);
            dispatch({ type: 'SET_FILTER', payload: filters });
            setFolders();
        }).catch(err => {
            console.error(err);
            handleError(err.response.headers.errormessage);
        });
    }

    const setFolders = () => {
        const EntityId = 1;
        serviceGetFolders(EntityId).then(data => {
            const folders = data.data;
                const resultsFolder = data.data;
                dispatch({ type: 'SET_RESULTS_Folder', payload: resultsFolder });
        }).catch(err => {
            console.error(err);
            handleError(err.response.headers.errormessage);
        });
    }

    const handleCities = (country) => {
        serviceCompanyCities(country).then(data => {
            const cities = data.data;
            const currentCity = state.query.filter.headOfficeCityList_Dropdown;            
            const newState = {...state.filters, headOfficeCityList_Dropdown : cities};
            if (currentCity !== "") {
                const payload = dropdownSelect(newState, {id: 'headOfficeCityList_Dropdown', value: currentCity});
                dispatch({type: 'EDIT_FILTER', payload: payload});
            }
            else {
                dispatch({type: 'SET_CITIES', payload: cities})
            }
        }).catch(err => {
            console.error(err);
            handleError(err.response.headers.message);
        })
    }
    const handleFilters = e => {
        const id = e.target.name || e.target.id;
        let payload;
        if (id.includes('Country')) {
            handleCities(e.target.value);
            payload = dropdownSelect(state.filters, e.target);
        }
        else if (id.includes("Checkbox")) {
            payload = checkboxSelect(state.filters, e.target);
        }
        else if (id.includes("Dropdown")) {
            payload = dropdownSelect(state.filters, e.target);
        }
        else if (id.includes("Slider")) {
            const slidervaltemp = e.target.value;
            const sliderval = slidervaltemp.toString().replace(',', '|');
            setSliderVal(sliderval);
        }
        //dispatch({ type: 'EDIT_FILTER', payload: payload });
        handleQuery(payload);
        //return payload;
    }
    const handleCompanyName = (fullOption, name) => {
        dispatch({type: 'SET_COMPANY_NAME', payload: {fullOption, name}});
    }
    const removeFilter = filter => {
        const id = filter.id;
        if (id.includes("Name")) {
            const  filteredList = state.companySearchValues.filter(value => value.companyName !== filter.text);
            const filteredName = state.companyName.filter(name => name !== filter.text);
            const isDefault = !filteredList.length;
            handleCompanyName(filteredList, filteredName);
            dispatch({type: 'SET_OPTION', payload: isDefault ? 2 : 0});
            dispatch({type: 'SET_DEFAULT_VIEW', payload: isDefault});
            const selectedFilters = handleFiltersList(filteredList, 'company');
            dispatch({type: "SET_SELECTED_FILTERS", payload: selectedFilters});
        }
        else {
            const payload = handleFilters({target: {...filter}});
            const selectedFilters = handleFiltersList(payload);
            dispatch({type: "SET_SELECTED_FILTERS", payload: selectedFilters});
            dispatch({type: 'SET_OPTION', payload: 1});
        }
        dispatch({type: 'SET_PAGE_NUMBER', payload: 1});
        dispatch({type: 'SET_SORTING', payload: defaultSort});
    }
    const handleClearFilters = () => {
        const payload = clearFilters(state.filters);
        setSliderVal("");
        state.query["filter"]["MarketCap_Slider"] = "";
        handleCompanyName([]);
        dispatch({type: 'EDIT_FILTER', payload: payload});
        handleQuery(payload);
        dispatch({type: 'SET_OPTION', payload: 1});
        dispatch({type: "SET_SELECTED_FILTERS", payload: []});
        dispatch({type: 'SET_PAGE_NUMBER', payload: 1});
        dispatch({ type: 'SET_SORTING', payload: defaultSort });
        dispatch({ type: 'SET_SLIDER_VAL', payload: [0, 500000] });
        
    }
    const handlePageNumber = (e, value) => {
        dispatch({type: 'SET_PAGE_NUMBER', payload: value});
    }
    const handleSearch = () => {
        handleLoading(true);
        setFolders();

        const body =  state.query;
        servicePostList(body).then(data => {
            setTimeout(() => {
                const results = data.data.companySearchResultList;
                dispatch({type: 'SET_RESULTS', payload: results});
                dispatch({type: 'SET_TOTAL_RESULTS', payload: data.data.totalResults});
                handleLoading(false);
            }, 100);
        }).catch(err => {
            handleLoading();
            handleError(err.response.headers.errormessage);
        });
    }
    const handleSearchRequest = (filtersList) => {
        if (filtersList) {
            const selectedFilters = handleFiltersList(filtersList, 'company');
            dispatch({type: "SET_SELECTED_FILTERS", payload: selectedFilters});
        }
        dispatch({type: "SET_PAGE_NUMBER", payload: 1});
        dispatch({type: 'SET_SORTING', payload: defaultSort});
    }
    const handleSearchFilter = () => {
        handleCompanyName([]);
        dispatch({type: 'SET_OPTION', payload: 1});
        handleSearchRequest(state.filters); 
    }
    const handleSearchInput = (name) => {
        dispatch({type: 'SET_OPTION', payload: 0});
        if (name) {
            handleCompanyName(name);
        }
        dispatch({type: 'SET_DEFAULT_VIEW', payload: handleNameInput(name || state.companyName)});
        handleSearchRequest({companyName: name || state.companyName})
    }
    const handleSearchMultipleInput = (filters) => {
        dispatch({type: 'SET_OPTION', payload: 0});
        dispatch({type: 'SET_DEFAULT_VIEW', payload: Boolean(!filters.length)});
        handleSearchRequest(filters);
    }
    const handleSearchSorted = (e) => {
        dispatch({type: "SET_PAGE_NUMBER", payload: 1});
        dispatch({type: 'SET_SORTING', payload: e});
    }
    const handlePageSize = e => {
        dispatch({type: 'SET_PAGE_SIZE', payload: parseInt(e.target.id)});
    }
    const handleSelectCompany = id => {
        dispatch({type: 'SET_COMPANY_ID', payload: id});
        history.push(`/company/${id}`);
    }
    const handleProfile = (e) => {
        handleLoading(true);

        serviceGetProfile(e).then(data => {
            dispatch({type: 'SET_PROFILE', payload: data.data});
            let payload;
            if (!!data.data.stockList.length) {
                const firstStock = data.data.stockList[0];
                payload = {value: firstStock.graphId, label: firstStock.exchange + ":" + firstStock.symbol};
                dispatch({type: 'SET_GRAPH_ID', payload: payload});
            }
            else {
                payload = {value: 0, label: ''};
            }
            dispatch({type: 'SET_GRAPH_ID', payload: payload});
            handleLoading(false);
        }).catch(err => {
            handleLoading(false);
            console.error(err);
            handleError(err.response.headers.errormessage);
        });
    }
    const handleAutoComplete = (name) => {
        serviceCompanyAutocomplete(name).then(data => {
            const list = data.data.companySearchResultList
            dispatch({type: 'SET_AUTOCOMPLETE', payload: list});
        }).catch(err => {
            console.error(err);
            handleError(err.response.headers.errormessage);
        });
    }
    const handleClearAutoComplete = () => {
        dispatch({type: 'SET_AUTOCOMPLETE', payload: []});
    }
    const handleGraphId = (e) => {
        const id = e.target.value;
        const selected = state.profile.stockList.find(stock => stock.graphId === id);
        const payload = {value: selected.graphId, label: selected.exchange + ":" + selected.symbol};
        dispatch({type: 'SET_GRAPH_ID', payload: payload});
    }
    const handleAddToPortfolio = (folderid, id, action) => {
        /*serviceAddPortfolio(id).then(() => {
            dispatch({type: 'ADD_TO_PORTFOLIO'});
        }).catch(err => {
            console.error(err);
            handleError(err.response.headers.errormessage);
        })*/
        const entity = 1;
        const body = { entityId: id.toString(), entityTypeId: entity, folderId: folderid }
        postPortfolioItem(body).then(() => {
            dispatch({ type: 'ADD_TO_PORTFOLIO' });
        }).catch(err => {
            console.error(err);
            handleError(err.response.headers.errormessage);
        })
    }

    const handlePortfolioOnSearch = (folderid, id, action) => {
        const entity = 1;
        const body = {entityId: id.toString(), entityTypeId: entity, folderId: folderid}
        if (!action) {
            postPortfolioItem(body).then(() => {
                const updatedResults = managePortfolioSearch(state.results, id);
                dispatch({type: 'SET_RESULTS', payload: updatedResults});
            }).catch(err => {
                console.error(err);
                handleError(err.response.headers.errormessage);
            })
        } else {
            deletePortfolioItem(body).then(() => {
                const updatedResults = managePortfolioSearch(state.results, id);
                dispatch({type: 'SET_RESULTS', payload: updatedResults});
            }).catch(err => {
                console.error(err);
                handleError(err.response.headers.errormessage);
            })
        }
    }

    const providerValue = {
        ...state,
        setFilters,
        removeFilter,
        handleFilters,
        handleCompanyName,
        handlePageNumber,
        handlePageSize,
        handleSearch,
        handleSearchInput,
        handleClearFilters,
        handleSearchFilter,
        handleSelectCompany,
        handleProfile,
        handleAutoComplete,
        handleClearAutoComplete,
        handleGraphId,
        handleAddToPortfolio,
        handleSearchSorted,
        handleSearchMultipleInput,
        handlePortfolioOnSearch,
        setFolders
    }

    return (
        <CompanyContext.Provider value={providerValue}>
            {children}
        </CompanyContext.Provider>
    )
}

export const useCompany = () => useContext(CompanyContext);